import mapboxgl from 'mapbox-gl';

export default {
	init() {
		prepare_mapbox();
	},
	finalize() {},
};

function prepare_mapbox() {
	if (!document.querySelector('#mapbox-container-container')) {
		return;
	}

	mapboxgl.accessToken =
		'pk.eyJ1IjoibWFyY3VzcGFpbnQiLCJhIjoiY2szb2hpbTkxMDQ2czNibmV4YTdxazdzZiJ9.dvVhWLTTXHNXIKWPrci-tQ';

	let maps = document.querySelectorAll('#mapbox-container-container');
	let i;

	for (i = 0; i < maps.length; ++i) {
		let marcusAddress = '';
		let mapBounds = '';

		
		let mapboxLocation = maps[i].getAttribute('data-map');

		if (mapboxLocation == 'nashville') {
			marcusAddress = new mapboxgl.LngLat(-86.7726688, 36.1325872);
			mapBounds = new mapboxgl.LngLatBounds([
				-86.77660957207604,
				36.12971998486283,
				-86.76705008575772,
				36.13574376293438,
			]);
		} else {
			marcusAddress = new mapboxgl.LngLat(-85.748811, 38.254965);
			mapBounds = new mapboxgl.LngLatBounds([
				-86.05746685652359,
				38.073885870431894,
				-85.39478216140083,
				38.45620237804695,
			]);	
		}

		let container = 'map-' + mapboxLocation;

		let map = new mapboxgl.Map({
			container: container,
			style: 'mapbox://styles/marcuspaint/ck3ohk2i55nxk1coavwg5cwpu',
			center: marcusAddress,
			maxBounds: mapBounds,
			zoom: 15,
		});
		new mapboxgl.Marker({ color: '#ee3e3b' }).setLngLat(marcusAddress).addTo(map);
	}
}

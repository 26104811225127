export default {
	init() {
		// JavaScript to be fired on all pages
		prepare_navbar();
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};

function prepare_navbar() {
	const menuBtn = document.querySelector('.menu-button');
	const header = document.querySelector('#masthead');

	menuBtn.addEventListener('click', () => {
		if (header.classList.contains('active')) {
			header.classList.remove('active');
		} else {
			header.classList.add('active');
		}
	});
}

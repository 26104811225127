import Glide, {
	Breakpoints,
	Controls,
	Swipe,
	Keyboard,
} from '@glidejs/glide/dist/glide.modular.esm';

export default {
	init() {},
	finalize() {
		prepareSlider();
	},
};

function prepareSlider() {
	const slider = document.querySelector('.glide');
	const slides = slider.querySelectorAll('.glide__slide');
	const bulletContainer = slider.querySelector('.glide__bullets');

	// create a corresponding bullet for each slide
	slides.forEach((_slide, idx) => {
		let bullet = document.createElement('button');
		bullet.classList.add('glide__bullet');
		bullet.dataset.glideDir = `=${idx}`;
		bulletContainer.appendChild(bullet);
	});

	// init slider
	new Glide('.glide', {
		type: 'carousel',
		gap: 25,
		peek: { before: 70, after: 150 },
		breakpoints: {
			769: {
				gap: 96,
				peek: {
					before: 0,
					after: 0,
				},
			},
		},
	}).mount({ Breakpoints, Controls, Swipe, Keyboard });
}

import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import contact from './routes/contact';
import timeline from './routes/timeline';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
	/** Contact page */
	contact,
	/** Timeline page */
	timeline,
});

/** Load Events */
document.addEventListener('DOMContentLoaded', () => routes.loadEvents());

import smoothscroll from 'smoothscroll-polyfill';

export default {
	init() {
		smoothscroll.polyfill();
		prepare_top_top();
		prepare_timeline();
	},
	finalize() {},
};

function prepare_top_top() {
	var button = document.querySelector('#back-top');
	var timeline = document.querySelector('.timeline-container').getBoundingClientRect().top + window.document.documentElement.scrollTop;

	button.addEventListener('click', function() {
		window.scrollTo({
			behavior: 'smooth',
			left: 0,
			top: timeline
		});
	});
}

function prepare_timeline() {
	var markers = document.querySelectorAll('.line button');

	for (var i = 0; i < markers.length; i++) {
		markers[i].addEventListener('click', function () {
			marker(this, markers);
		});
	}

	document.addEventListener('keyup', function(e) {
		if (e.keyCode == 9) {
			var selectedElement = document.querySelector(':focus');

			if (selectedElement && selectedElement.classList.contains('marker')) {
				e.preventDefault();

				var timeline = document.querySelector('.timeline-container').getBoundingClientRect().top + window.document.documentElement.scrollTop;
				var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

				marker(selectedElement, markers);

				if (currentScroll > timeline) {
					window.scrollTo({
						behavior: 'smooth',
						left: 0,
						top: timeline
					});
				}
			}
		}
	});

	document.querySelector('.line button').focus();
}

function marker(element, markers) {
	var id = element.getAttribute('data-marker');
	var matchingEvents = document.querySelectorAll('.event-' + id);
	var events = document.querySelectorAll('.events .event');

	for (var i = 0; i < markers.length; i++) {
		markers[i].classList.remove('selected-marker');
	}
	for (i = 0; i < events.length; i++) {
		events[i].classList.remove('show');
	}

	element.classList.add('selected-marker');

	for (i = 0; i < matchingEvents.length; i++) {
		matchingEvents[i].classList.add('show');
	}
}
